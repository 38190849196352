/*
  Variables
*/

:root{
  --color-black: #00171F;
  --color-orange: #F15152;
  --color-blue: #1E90FF;
  --color-red: #B8336A;
  --color-yellow: #FF9F1C;
  --color-light-green: #6DD3A2;
  --color-brown:#705036;
  --color-green: #006372;

  --font-navigation: Regular 24px Roboto;
}

hr{
  color: white;
}

.ui .rail {
  top: 16px;
  width: 0;
}

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.container {
    width: 90% !important;
  }
}   */

/*
* Main Color
*/

.main-color{
  color: #EC4E20 !important;
}

.text-underline-decoration{
  text-decoration: underline;
} 

.color-grey{
  color: #606060;
}

.about {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.about h1 {
  margin-top: 10% !important;
}
.about h4 {
  margin-top: 0 !important;
}
.about p {
  margin-top: auto;
  margin-bottom: 10%;
}

/*
* Copy right Style
*/
.copy-rights{
  text-decoration: underline;
  position: relative;
  top: 0.5rem;
  font-size: 20px;
  color: #606060;
  line-height: 28px;
}

/*
  Terminal Style
*/

.navigation{
  font:var(--font-navigation) !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;
}
.navigation .nav-overview {
  color: var(--color-black) !important;
} 
.navigation .nav-education {
  color: var(--color-orange) !important;
} 
.navigation .nav-experiences {
  color: var(--color-blue) !important;
} 
.navigation .nav-services {
  color: var(--color-red) !important;
} 
.navigation .nav-workflow {
  color: var(--color-yellow) !important;
} 
.navigation .nav-testimonials {
  color: var(--color-light-green) !important;
} 
.navigation .nav-prices {
  color: var(--color-brown) !important;
} 
.navigation .nav-contact {
  color: var(--color-green) !important;
} 


.bar {
  text-align: center;
  width: 100%;
  height: 25px;
  background: var(--color-black);
  margin: 0 auto;
  font-family: monospace;
  float: none;
  border-radius: .5rem .5rem 0 0;
}
.btn {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin: 0 auto;
  position:relative;
  display: inline-block;
  top: 4px;
}
.btn-red {
  background-color: #F15152;
  left: -42%;
}
.btn-yellow{
  background-color: #FF9F1C;
  left: -40%;
}
.btn-green{
  background-color: #6DD3A2;
  left: -38%;
}
.screen {
  background-color: #fff;
  width: 100%;
  height: 250px;
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: -0.5rem 0.25rem 1.75rem 0 rgba(0, 0, 0, 0.52);
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.terminal-wrapper{
  position: fixed !important;
  max-width: 40%;
}

.font {
  color: #606060;
  font-family: monospace;
  font-size: 15px;
  text-align: left;
  position: static;
}

.terminal-input{
  background: transparent;
  border: none !important;
  color:var(--color-black) !important;
  margin-left:2px;
  height: 20px;
  width: 50%;
  font-weight: 600;
  outline: none;
}

.terminal-input:hover,.terminal-input:active,.terminal-input:focus {
  background: transparent;
  border: none !important;
  color:var(--color-black) !important;
  font-weight: 600;
}

.right-section{
  padding-top: 50px;
}

.workflow-list {
  background: #EC4E20;
  border-radius: 100%;
  color:#fff;
  width: 67px;
  height: 67px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  display: inline-block
}
.workflow-list p {
  font-size: 30px;
  line-height: 65px;
}

/*
* Sticky main dev
*/
.sticky-right-section{
  position: fixed;
  width: 50%
}

.sticky-top {
  position: fixed;
  left: auto;
  right: auto;
  max-width: 557px;
  top: 0;
  bottom: auto;
}
/*
* services
*/

.services-description{
  font: normal 20px/24px Segoe UI;
}

.services-box h2 {
  font: bold 16px/24px Segoe UI !important;
}

.services-box p {
  text-decoration: underline;
  color: #777;
  text-align: center;
  font: normal 15px/24px Segoe UI;
}


.testimonial{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 6px #00000029 !important;
  border: 1px solid #00171F !important;
  border-radius: 0 !important;
  font-size: 18px !important;
  line-height: 27px;
  color: #606060;
}
.testimonial-platform{
  float:left;
  /* font-weight: bold;
  margin-bottom: 0; */
}
.testimonial-client{
  float: right;
  /* font-weight: bold; */
}

.price-box{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 6px #00000029 !important;
  border: 1px solid #00171F !important;
  border-radius: 0 !important;
}

.services-img {
  margin: 0 0 10px;
  width: auto;
  height: 200px;
  background: transparent no-repeat 50%;
  background-size: contain;
}
.services-img img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.hobby{
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}

/*
* Experience Description paragraph
*/

.experience-job-desc{
  font-size: 17px;
  line-height: 27px;
  color: #606060;
}

/*
* Workflow Description paragraph
*/

.workflow-desc{
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #606060;
}

.price-box-selection :nth-child(4) .price-box {
  padding: 1rem 1px !important;
}
.price-box-selection :nth-child(4) .price-box hr{
  width: 90%;
}

.certificate-year {
  color: #00171F;
  font: Italic 14px/20px Verdana;
  white-space: nowrap 
}
.collage-school{  
  margin-top: 1rem
}

.contact-links {
  text-align: start;
}

.cv {
  text-align: end;
}

.cv-button{
  color: #fff;
  text-shadow: none;
  background-image: none;
  font-weight: 600;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  padding: 0.7rem 1.2rem;
  border-radius: 20px;
  background-color: #EC4E20;
}

.cv-button a {
  color: white !important;
  text-decoration: none !important;
}

.availability {
  margin: 2rem 0
}

@media (max-width: 767px){
  .certificate-year{
    font-size: 1rem;
    white-space: nowrap
  }
  .collage-school{  
    margin-left: 1rem
  }
  .contact-links {
    text-align: start;
  }
  .cv {
    text-align: end;
  }
}
@media (max-width: 360px){
  .collage-school{  
    margin-left: 3rem
  }
}
@media (min-width: 360px){
  .contact-links {
    text-align: center;
  }
  .cv {
    text-align: center;
  }
}
@media (min-width: 767px){
  .contact-links {
    text-align: start;
  }
  .cv {
    text-align: end;
  }
}

@media (min-width: 1199px){
  .terminal-wrapper{
    max-width: 537.2px;
  }
}